import React from "react";
import Layout from "../components/layout";
import { Row, Col, Ratio } from "react-bootstrap";
import Seo from "../components/seo";

const Contact = () => (
    <Layout>
        <Seo title="Contáctenos" description="Información de contacto de CoopGestión"  />
        <Row className="p-5">
            <Col md>
                <div className='text-center'>
                    <h5 >
                        Agencia Matriz
                    </h5>
                    <p>Av. La Prensa N66-37 y Lizardo Ruíz. <br />Teléfono: 022534689</p> 
                    <a href = "mailto: info@coopgestion.fin.ec">info@coopgestion.fin.ec</a><br/>
                    Quito-Ecuador
                </div>
            </Col>
            <Col md>
                <div className='p-1'>
                    <Ratio className="embed-responsive-16by9">
                        <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d498.72630410877!2d-78.49508260153948!3d-0.11392789789068711!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x91d585747c9b92a7%3A0x971958804791dab5!2sCoopgestion!5e0!3m2!1sen!2sec!4v1587952574055!5m2!1sen!2sec" 
                        className='embed-responsive-item' title="Ubicación agencia matriz"></iframe>
                    </Ratio>
                </div>
            </Col>
        </Row>
    </Layout>
);

export default Contact;